import {FooterHelp, Link} from '@shopify/polaris-internal';
import {useI18n} from '@shopify/react-i18n';
import React from 'react';

import {APP_NAME_SHORT} from '~/utils/constants';
import {createI18nConfig} from '~/utils/createI18nConfig';

import translations from './translations';

interface PageFooterHelpProps {
  resourceName: 'filters' | 'productBoosts' | 'synonyms' | 'recos' | 'overview';
}

export function PageFooterHelp({resourceName}: PageFooterHelpProps) {
  const [i18n] = useI18n(createI18nConfig(translations));

  const {resource, url}: {resource: string; url: string} = {
    filters: {
      resource: i18n.translate('PageFooterHelp.resources.filters'),
      url: 'https://help.shopify.com/manual/online-store/search-and-discovery/filters',
    },
    productBoosts: {
      resource: i18n.translate('PageFooterHelp.resources.productBoosts'),
      url: 'https://help.shopify.com/manual/online-store/search-and-discovery/search#product-boosts',
    },
    synonyms: {
      resource: i18n.translate('PageFooterHelp.resources.synonyms'),
      url: 'https://help.shopify.com/manual/online-store/search-and-discovery/search#synonyms',
    },
    recos: {
      resource: i18n.translate('PageFooterHelp.resources.recos'),
      url: 'https://help.shopify.com/manual/online-store/search-and-discovery/product-recommendations',
    },
    overview: {
      resource: APP_NAME_SHORT,
      url: 'https://help.shopify.com/manual/online-store/search-and-discovery',
    },
  }[resourceName];

  return (
    <FooterHelp>
      {i18n.translate('PageFooterHelp.learnMore', {
        resourceName: (
          <Link url={url} target="_blank">
            {resource}
          </Link>
        ),
      })}
    </FooterHelp>
  );
}
